<template>
  <div id="orderList-tab">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <el-input
                placeholder="请输入车牌号"
                v-model="filtrate.keyword"
                @keyup.enter.native="getList(1)"
            >
              <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
        </ul>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
      >
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="platformType" label="支付平台" align="center">
        </el-table-column>
        <el-table-column label="订单状态" align="center">
          <template slot-scope="scope">
            <span
                v-if="scope.row.orderStatus === '订单生成'"
                class="payStatus shengcheng"
            >订单生成</span
            >
            <span
                v-if="scope.row.orderStatus === '待支付'"
                class="payStatus daizhifu"
            >待支付</span
            >
            <span
                v-if="scope.row.orderStatus === '支付成功'"
                class="payStatus chenggong"
            >支付成功</span
            >
            <span
                v-if="scope.row.orderStatus === '支付失败'"
                class="payStatus shibai"
            >支付失败</span
            >
            <span
                v-if="scope.row.orderStatus === '交易关闭'"
                class="payStatus awaitPay"
            >交易关闭</span
            >
            <span
                v-if="scope.row.orderStatus === '交易取消'"
                class="payStatus awaitPay"
            >交易取消</span
            >
            <span
                v-if="scope.row.orderStatus === '已退款'"
                class="payStatus tuikuan"
            >已退款</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总金额" align="center">
        </el-table-column>
        <el-table-column prop="payAmount" label="实付金额" align="center" width="70px">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="payTime" label="支付时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page.sync="page"
            @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData:[],
      loading:false,
      total: 0,
      page: 1,
      pageSize: 10,
      filtrate: {
        keyword: ""
      }
    }
  },
  computed: {
    info() {
      return this.$store.state.GangTing.info
    },
    parkingLotId(){
      return this.$store.state.GangTing.parkingLotId
    }
  },
  watch:{
    info(){
      this.getList();
    },
    parkingLotId: {
      handler(id) {
        if(id){
          this.page = 1
          this.getList()
        }
      },
      immediate: true
    }
  },
  methods:{
    async getList(page=this.page) {
      try {
        this.loading = true;
        let res = await this.$http.post("/order/list", {
          ...this.filtrate,
          pageNum: page,
          pageSize: this.pageSize,
          parkingLotId:this.parkingLotId
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.payTime = item.payTime?this.$moment(item.payTime).format(
                "yyyy-MM-DD HH:mm:ss"
            ):"--";
            switch (item.refundStatus){
              case "申请退款中":
                item.refundStatusText = "等待审批"
                break;
              case "已同意":
                item.refundStatusText = "已同意"
                break;
              case "已拒绝":
                item.refundStatusText = "已拒绝(重新申请)"
                break;
              default :
                item.refundStatusText = "申请退款"
            }
          }
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
#orderList-tab {

}
</style>
